import React from 'react';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { BlockLink, Block, Card, Image, Text } from '@hz-design-system/web-ui';

import Classes from './Carousels.scss';
import { TCarouselSearchItem } from 'types/TCarousels';

type TCarouselItemProps = {
  item: TCarouselSearchItem;
  withSubtitle?: boolean;
  withBackgroundColor?: boolean;
  sizeClass?: string;
  onClick: Function;
  eagerLoadImage?: boolean;
};

const CarouselItem = ({
  item,
  withBackgroundColor,
  sizeClass = '',
  withSubtitle,
  onClick,
  eagerLoadImage = false,
}: TCarouselItemProps) => {
  const { t } = useI18nContext();
  const imageSrc = item.imageSrc;
  const imageSrc3xIndex = 2;

  return (
    <BlockLink href={item.url} className={`${Classes.item} ${sizeClass}`} onClick={onClick}>
      <Card className={Classes.itemCard}>
        {Boolean(imageSrc.length) && (
          <Image
            className={`${Classes.image} ${withBackgroundColor ? Classes.imageWithBackground : ''}`}
            src={imageSrc?.[0]}
            srcSet={`${imageSrc?.[0]} 1x, ${imageSrc?.[1]} 2x, ${imageSrc?.[imageSrc3xIndex]} 3x`}
            alt={t(`cars.carousels.items.${item.key}.title`)}
            loading={eagerLoadImage ? 'eager' : 'lazy'}
            fetchpriority={eagerLoadImage ? 'high' : 'low'}
            decoding={eagerLoadImage ? 'sync' : 'async'}
          />
        )}
        {!Boolean(imageSrc.length) && <div className={`${Classes.image} ${Classes.skeletonImage}`}></div>}
        <Block>
          <Text.Large as="strong" className={Classes.itemTitle}>
            {t(`cars.carousels.items.${item.key}.title`)}
          </Text.Large>
          {withSubtitle && (
            <Text.Small className={Classes.itemSubtitle}>{t(`cars.carousels.items.${item.key}.subtitle`)}</Text.Small>
          )}
        </Block>
      </Card>
    </BlockLink>
  );
};

export default CarouselItem;
